<template>
  <div class="helpCenter">
    <template v-if="$route.name == 'helpCenter'">
      <!-- <div class="guide-coloum-cont help-cont" v-for="(item, index) in guideData" :key="index">
        <div class="coloum-title"><img class="ring-icon" src="../../assets/img/ring-icon.png" alt="">{{item.name}}</div>
        <div class="article-cont">
          <div class="article-title" v-for="(ite, ind) in item.children" :key="ind" @click="jumpEvent(ite)">{{ite.title}}</div>
        </div>
      </div>
      <div class="question-coloum-cont help-cont">
        <div class="coloum-title"><img class="ring-icon" src="../../assets/img/ring-icon.png" alt="">常见问题</div>
        <div class="article-cont">
          <div class="article-title" v-for="(item, index) in questionData" :key="index" @click="jumpEvent(item)">{{item.title}}</div>
        </div>
      </div> -->
      <div class="cont-list">
        <div class="cont-list-title">{{helpType == 1 ? '操作指引' : '常见问题'}}</div>
        <template v-if="helpType == 1 && guideData.length != 0">
          <div class="lists" v-for="(item, index) in guideData" :key="index">
            <div class="lists-title"><span></span>{{item.name}}</div>
            <div class="lists-item" v-for="(ite, ind) in item.children" :key="ind" @click="jumpEvent(ite)">
              <div class="lists-item-data">
                <span>{{ite.title}}</span>
                <img src="../../assets/img/toDetail.png" alt="">
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="helpType == 2 && questionData.length != 0">
          <div class="lists-item common-item" v-for="(ite, ind) in questionData" :key="ind" @click="jumpEvent(ite)">
            <div class="lists-item-data">
              <span>{{ite.title}}</span>
              <img src="../../assets/img/toDetail.png" alt="">
            </div>
          </div>
        </template>
        <template v-else-if="guideData.length == 0 || questionData.length == 0">
          <img class="noData" src="../../assets/img/noData.png" alt="">
        </template>
      </div>
      <div class="page-header">
        <div class="head-item" v-for="(item, index) in itemList" :key="index" @click="selectEvent(item)">
          <img :src="item.src" alt="">
          <span>{{item.title}}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
export default {
  name: 'helpCenter',
  data() {
    return {
      itemList: [
        {
          title: '操作指引',
          src: require('../../assets/img/operationInstructions.png'),
          id: null,
          helpType: 1
        },
        {
          title: '常见问题',
          src: require('../../assets/img/commonProblem.png'),
          id: null,
          helpType: 2
        }
      ],
      guideData: [],
      questionData: [],
      helpType: 1,
      isExam: false, // 是否成考账号
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if(newVal.name == 'helpCenter') {
          this.guideData = []
          let type = this.$store.state.deviceType
          if(newVal.query.hasOwnProperty('accountType') && newVal.query.accountType == 4) {
            this.isExam = true
            this.itemList[0].id = type == 1 || type == 4 ? 46 : 45
            // this.itemList[1].id = type == 1 || type == 4 ?  : 
          } else {
            this.itemList[0].id = type == 1 || type == 4 ? 22 : 8
            this.itemList[1].id = type == 1 || type == 4 ? 23 : 12
          }
          this.getColumnList(this.itemList[0].id)
        }
      }
    }
  },
  methods: {
    selectEvent(item) {
      this.helpType = item.helpType
      if(item.helpType == 1)
        this.getColumnList(this.itemList[0].id)
      else if(item.helpType == 2 && !this.isExam)
        this.getData()
    },
    async getData() {
      this.questionData = await this.getArticleBycolIdEvent(this.itemList[1].id);
      console.log(this.questionData, 911)
    },
    // 获取栏目
    getColumnList(id) {
      this.$request.getColumn({id}).then(res => {
        if(res.data.code == 0) {
          this.getArtcleTitle(res.data.data);
        }
      })
    },
    // 获取每个栏目的文章标题
    async getArtcleTitle(data, type) {
      if(data.length == 0) return;
      for(let ele of data) {
        let titleObj = await this.getArticleBycolIdEvent(ele.id);
        ele.children = titleObj;
      }
      this.guideData = data;
      console.log(data,985211)
    },
    // 通过栏目id获取文章列表
    getArticleBycolIdEvent(id) {
      return new Promise((resolve, reject) => {
        this.$request.getArticleBycolId({id}).then(res => {
          if(res.data.code == 0) {
            resolve(res.data.data);
          }
        })
      })
    },
    jumpEvent(item) {
      this.$router.push({name: 'helpDetails', query: {
        id: item.id
      }})
    }
  },
}
</script>

<style lang='scss'>
  .helpCenter {
    height: 100%;
    background-color: #F9F9F9;
    // overflow: scroll;
    // overflow-x: hidden;
    .cont-list {
      height: calc(100vh - 60px);
      padding: 0 16px;
      overflow: scroll;
      overflow-x: hidden;
      .cont-list-title {
        font-size: 16px;
        padding: 18px 16px 17px;
        font-weight: 400;
        color: #666;
      }
      .lists {
        color: #363840;
        .lists-title {
          padding: 16px 17px;
          font-size: 16px;
          display: flex;
          align-items: center;
          background-color: #fff;
          span {
            width: 2px;
            height: 14px;
            display: inline-block;
            background-color: #1A7EE2;
            margin-right: 6px;
          }
        }
      }
      .lists-item {
        background-color: #FBFBFB;
        .lists-item-data {
          padding: 18px 17px 18px 25px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          img {
            width: 10px;
            height: 14px;
          }
        }
      }
      .common-item {
        background-color: #fff;
      }
      .noData {
        width: 200px;
        height: 150px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .page-header {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      background-color: #fff;
      // padding-bottom: 20px;
      box-shadow: 0px -1px 3px rgba(105, 105, 105, .3);
      .head-item {
        height: 30px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        color: #363840;
        img {
          width: 15px;
          height: 17px;
          margin-right: 6px;
        }
        &:first-child {
          border-right: 1px solid #EFEFEF;
        }
      }
    }
    // .help-cont {
    //   .coloum-title {
    //     margin: 10px 0 0;
    //     font-size: 16px;
    //     .ring-icon {
    //       width: 6px;
    //       height: 6px;
    //       margin-right: 6px;
    //     }
    //   }
    //   .article-cont {
    //     // box-sizing: border-box;
    //     // padding-left: 6px;
    //     .article-title {
    //       font-size: 14px;
    //       padding: 10px 0;
    //       border-bottom: 1px solid #f1f1f1;
    //       cursor: pointer;
    //       box-sizing: border-box;
    //       padding-left: 20px;
    //     }
    //   }
    // }
  }
</style>